@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

body {
  font-family: "Open Sans", sans-serif;
}

h3 {
  margin-top: 0;
}

.div-fade {
  transition-duration: 0.3s;
}

b,
strong {
  font-weight: 700;
}

// #root {
// 	min-height: 100vh;
// 	#content {
// 		min-height: 100vh;
// 		// padding-top: 2rem;
// 		display: flex;
// 	}
// 	#layout-bottom {
// 		position: fixed;
// 		bottom: 0;
// 		left: 0;
// 		width: 100%;
// 	}
// }

#root {
  padding-top: 3.5rem;
  padding-bottom: 10rem;
}

.btn {
  &.btn-rounded {
    border-radius: 50%;
    padding: 0.75rem 1.25rem;
    width: 3rem;
    height: 3rem;
  }

  &.btn-shadow {
    background-color: #fff;
    padding: 0;

    &.s-1 {
      box-shadow: 0 1px 3px rgba(8, 6, 6, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
    }
    &.s-2 {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.115);
    }
  }
}

.back {
  position: fixed;
  top: 2rem;
  z-index: 100;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  svg:not(:root).svg-inline--fa {
    height: 1.5rem;
    width: 1.5rem;
    margin-top: 0.7rem;
  }
}
