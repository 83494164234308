@import "~react-image-gallery/styles/scss/image-gallery.scss";
.image-gallery-slide:not(:fullscreen) {
  background: #fff;
  img {
    width: auto;
    height: 500px;
    max-width: 80vw;
  }
}

.image-gallery-icon {
  &:hover {
    color: #55908b;
  }
  &:focus {
    outline: none;
  }
}

.image-gallery-thumbnail {
  &.active,
  &:hover,
  &:focus {
    outline: none;
    border: 4px solid #55908b;

    @media (max-width: $ig-small-screen) {
      border: 3px solid #55908b;
    }
  }
}

.point-of-interest {
  .poi-badge {
    width: 4em;
    height: 4em;
    display: flex;
    align-items: center; /* Vertical */
    justify-content: center; /* Horizontal */

    h4 {
      color: inherit;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
  &.tease {
    margin-bottom: 1rem;
  }
  &.full {
  }
}
